import React, { useState , useEffect , useRef} from "react";
import gsap from "gsap";
import { Download1,Download2,Download3,Download4 , MockupBg, PlayIcon} from "../assets";
import Footer from "./Footer";

const Download = () => {
  const myComponentRef = useRef(null);
  const myComponentRef2 = useRef(null);

  useEffect(() => {
    // Use GSAP to animate the component
    gsap.from(myComponentRef.current, {
      x : -150, // Move from top (you can adjust this value)
      opacity: 0, // Start with opacity 0 for fade effect
      duration: 4, // Animation duration in seconds
      ease: 'power3.out', // Easing function (optional)
    });
  }, []);
  useEffect(() => {
    // Use GSAP to animate the component
    gsap.from(myComponentRef2.current, {
      x: 150, // Move from top (you can adjust this value)
      opacity: 0, // Start with opacity 0 for fade effect
      duration: 4, // Animation duration in seconds
      ease: 'power3.out', // Easing function (optional)
    });
  }, []);
  return (
    <div
      className="h-fit w-screen bg-cover bg-center p-8 md:p-24"
      style={{ backgroundImage: `url("${MockupBg}")` }}
    >
      <h1 className="text-blue-500 md:text-7xl  text-l text-center font-black">Legal Consultancy at your fingertips</h1>
      <div className="flex flex-col-reverse md:flex-row md:px-24 gap-[3em] justify-around">
        {/* Left Section */}
        <div className="bg-white bg-opacity-15 backdrop-blur-xl h-fit md:my-24 my-8 p-8 w-fit rounded-2xl  md:w-[500px] pl-20 md:pl-16" ref={myComponentRef}>
            <h2 className="text-xl text-gray-300">Check Out</h2>
            <h2 className="md:text-6xl text-white font-black md:p-2 p-1 text-3xl">
                Amicable App
            </h2>
            <h2 className="text-l text-gray-300 text-baseline">on <button className="bg-[#0D90C2] rounded-xl px-3 text-white md:text-2xl font-sans"> <img src={PlayIcon} alt="" className="h-[30%] w-[30%] inline-block p-1" />Playstore</button></h2>
        </div>

        {/* Right Section */}
        <div className="p-8 pl-0 md:pl-8 flex flex-wrap justify-around items-center max-w-[400px] max-h-[300px] gap-8" ref={myComponentRef2}>
          {/* Four Images */}
          <div className="flex gap-8">
          <div className="w-1/2 md:w-full mb-4 md:mb-0">
            <img src={Download1} alt="Image 1" className="w-full h-full rounded-xl hover:scale-110 ease-in-out" />
          </div>
          <div className="w-1/2 md:w-full mb-4 md:mb-0">
            <img src={Download2} alt="Image 2" className="w-full h-full rounded-xl hover:scale-110 ease-in-out" />
          </div>
          </div>
          <div className="flex gap-8">
          <div className="w-1/2 md:w-full mb-4 md:mb-0">
            <img src={Download3} alt="Image 3" className="w-full h-full rounded-xl hover:scale-110 ease-in-out" />
          </div>
          <div className="w-1/2 md:w-full">
            <img src={Download4} alt="Image 4" className="w-full h-[88%] md:h-full rounded-xl hover:scale-110 ease-in-out"/>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
