import React, { useState , useEffect , useRef} from "react";
import gsap from "gsap";
import {
  CardImg1,
  CardImg2,
  CardImg3,
  CardImg4,
  AboutBg,
  BackgroundAboutPhone
} from "../assets";

const YourComponent = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const myComponentRef = useRef(null);
  const myComponentRef2 = useRef(null);

  useEffect(() => {
    // Use GSAP to animate the component
    gsap.from(myComponentRef.current, {
      y: -150, // Move from top (you can adjust this value)
      opacity: 0, // Start with opacity 0 for fade effect
      duration: 3, // Animation duration in seconds
      ease: 'power3.out', // Easing function (optional)
    });
  }, []);
  useEffect(() => {
    // Use GSAP to animate the component
    gsap.from(myComponentRef2.current, {
      x: -250, // Move from top (you can adjust this value)
      opacity: 0, // Start with opacity 0 for fade effect
      duration: 3, // Animation duration in seconds
      ease: 'power3.out', // Easing function (optional)
    });
  }, []);

  const choices = [
    {
      title: "Our Company",
      description:
        "With a legacy spanning two generations in the legal field, Amicable emerges as a transformative force in dispute resolution. We recognize, through profound experience, that parties engaged in disputes seek an immediate and unbiased resolution—a need often unmet due to the lack of infrastructure, resulting in protracted litigations that burden the courts. This realization fueled our search for an innovative, tech-assisted resolution process that could redefine the psychology of dispute resolution and promote open dialogue. At Amicable, our platform represents more than just a solution; it embodies a commitment to change. We bring forth a unique blend of technology and expertise to provide swift, efficient, and impartial resolutions, alleviating the strain on traditional legal avenues. Join us on this journey toward a future where conflicts are met with innovation, efficiency, and a dedication to fostering meaningful dialogue. Experience the evolution of dispute resolution with Amicable.",
      images: [CardImg1,
        CardImg2,
        CardImg3,
        CardImg4],
    },
    {
      title: "Our Mission",
      description:
        "At Amicable, we are driven by a mission to deliver resolutions with the insight of experienced professionals. Our commitment is rooted in providing a unique approach that combines legal acumen and emotional understanding to foster amicable solutions.",
      images: [CardImg1,
        CardImg2,
        CardImg3,
        CardImg4],
    },
    {
      title: "Team Profile",
      description:
        "Meet Our Experienced PeacemakersOur team brings a wealth of experience to the table, including a background in legal analysis and a deep understanding of human emotions. They are uniquely positioned to guide parties towards fair and empathetic resolutions.",
      images: [CardImg1,
  CardImg2,
  CardImg3,
  CardImg4],
    },
  ];

  const handleChoiceClick = (index) => {
    setSelectedIndex(index);
  };

  const smallScreenStyles = {
    background: `url("${BackgroundAboutPhone}")`,
    backgroundSize: 'cover',
  };

  const largeScreenStyles = {
    background: `url("${AboutBg}")`,
    backgroundSize: 'cover',
  };

  return (
    <div
      className="bg-[#101010] h-fit w-screen flex flex-col items-center py-3 justify-center bg-no-repeat bg-right"
      style={{ ...smallScreenStyles, '@media(min-width: 768px)': largeScreenStyles }}
    >
      <div className="flex p-[10px] md:p-12 md:gap-16">
        <div className="flex flex-col items-center justify-center mr-2">
          {choices.map((choice, index) => (
            <button
              key={index}
              onClick={() => handleChoiceClick(index)}
              className={`mb-2 px-4 py-2 rounded-xl border-solid ${
                selectedIndex === index
                  ? "bg-white text-gray-900 border-black border"
                  : "bg-gray-900 text-white border-white border"
              }`}
            >
              {index + 1}
            </button>
          ))}
        </div>

        {selectedIndex !== null && (
          <div className="rounded-lg :border :border-opacity-25 :border-gray-300 bg-opacity-5 bg-gray-300 backdrop-filter backdrop-blur-2xl text-white md:w-[55%] px-2 py-2 md:p-5" ref={myComponentRef}>
            <h2 className="text-4xl font-black text-center text-blue-500 md:text-7xl py-2" style = {{ color : "#0D90C2"}}>
              ABOUT US
            </h2>
            <h3 className="text-xl font-bold sm:text-xs text-center md:text-3xl">
              {choices[selectedIndex].title}
            </h3>
            <p className="text-center text-xs md:text-l text">{choices[selectedIndex].description}</p>
          </div>
        )}
      </div>
      {selectedIndex !== null && (
        <div className="flex mt-5 flex-wrap gap-4 mx-auto pl-16 w-full md:ml-24" ref={myComponentRef2}>
          {choices[selectedIndex].images.map((image, imageIndex) => (
            <img
              key={imageIndex}
              src={image}
              alt={`Image ${imageIndex + 1}`}
              className="mb-2 sm:w-[35%] w-[35%] md:w-44 md:h-44 hover:scale-110 ease-in-out rounded-xl"
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default YourComponent;
