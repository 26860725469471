import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

// Register the ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

const MyComponent = () => {
  const component1Ref = useRef(null);
  const component2Ref = useRef(null);

  useEffect(() => {
    // Set up GSAP timeline with ScrollTrigger
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: component1Ref.current,
        start: 'top 80%', // Adjust the start position based on your needs
        end: 'bottom 20%', // Adjust the end position based on your needs
        toggleActions: 'play none none reverse', // Animation direction
      },
    });

    // Animation for Component 1
    tl.from(component1Ref.current, {
      y: -50,
      opacity: 0,
      duration: 1,
      ease: 'power3.out',
    });

    // Animation for Component 2
    tl.from(component2Ref.current, {
      height: 0,
      opacity: 0,
      duration: 1,
      ease: 'power3.out',
    });
  }, []); // Run the animation setup only once when the component mounts

  return (
    <div>
      <div ref={component1Ref}>
        {/* Component 1 JSX */}
        <h1>Component 1 Content</h1>
      </div>
      <div ref={component2Ref}>
        {/* Component 2 JSX */}
        <div style={{ height: '100px', background: 'lightblue' }}>
          <h1>Component 2 Content</h1>
        </div>
      </div>
    </div>
  );
};

export default MyComponent;
