import React from 'react';
import { MainLogo } from '../assets';
import { SocialIcon } from 'react-social-icons'

const Footer = () => {
  return (
    <footer className="bg-[#1a4e6abf] text-white py-8 pb-0 w-screen">
      <div className="container gap-4  flex lg:flex-row px-4 md:justify-start">
        {/* Logo Section */}
        <div className="mb-4 lg:mb-0 hidden md:block">
          <img
            src={MainLogo}
            alt="Logo"
            className="hidden lg:block h-32 w-auto"
          />
        </div>

        {/* Quick Links Section */}
        <div className="mb-4 lg:mb-0">
          <h3 className="text-lg font-semibold mb-2">Quick Links</h3>
          <ul>
            <li><a href="#" className="text-[#D9D9D9] hover:text-white underline">Mobile App</a></li>
            <li><a href="#" className="text-[#D9D9D9] hover:text-white underline">Help & support</a></li>
            <li><a href="#" className="text-[#D9D9D9] hover:text-white underline">Feedback</a></li>
            <li><a href="#" className="text-[#D9D9D9] hover:text-white underline">Terms & Conditions</a></li>
            <li><a href="#" className="text-[#D9D9D9] hover:text-white underline">Privacy Policy</a></li>
          </ul>
        </div>

        {/* Follow Us Section */}
        <div className=''>
          <h3 className="text-lg font-semibold mb-2 underline text-[#D9D9D9]">About Us</h3>
          <h3 className="text-lg font-semibold mb-2 underline text-[#D9D9D9]">Follow Us</h3>
          <div className="flex gap-1">
            <a href="#" className="text-gray-400 hover:text-white">
            <SocialIcon url="https://linkedin.com"/>
            </a>
            <a href="#" className="text-gray-400 hover:text-white">
            <SocialIcon url="https://instagram.com"/>
            </a>
            <a href="#" className="text-gray-400 hover:text-white">
            <SocialIcon url="https://twitter.com"/>
            </a>
          </div>
        </div>
      </div>
      <p className="text-center text-white text-md py-2">© All rights reserved to Digilex Astute 2024.</p>
    </footer>
  );
};

export default Footer;
