import Bottom from "./components/Bottom";
import Download from "./components/Download";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import About from "./components/About";
import gsap from "gsap";
import MyComponent from "./components/Test";

function App() {
  return (<>
    <Navbar/>
    <div id="home">
    <Hero/>
    </div>
    <div id="about">
    <About/>
    </div>
    <div id="download">
    <Download/>
    </div>
    <div id="contact">
    <Bottom/>
    </div>
  </>
  );
}

export default App;
