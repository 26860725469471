import React from 'react'
import { HeroBg , MainLogo} from '../assets'
import { useEffect, useRef } from 'react';
import gsap from 'gsap';

export default function Hero() {
  const myComponentRef = useRef(null);

  useEffect(() => {
    // Use GSAP to animate the component
    gsap.from(myComponentRef.current, {
      y: -150, // Move from top (you can adjust this value)
      opacity: 0, // Start with opacity 0 for fade effect
      duration: 5, // Animation duration in seconds
      ease: 'power3.out', // Easing function (optional)
    });
  }, []);
  return (
    <div className="bg-cover bg-center h-screen w-screen relative" style={{ backgroundImage: `url(${HeroBg})` }}>
      <div className="absolute inset-0 flex items-center justify-center">
        <div className=" bg-opacity-70 p-8 rounded-lg max-w-[650px]  text-center md:w-[700px" ref={myComponentRef}>
          {/* Top Container */}
          <div className="flex justify-center">
            <img src={MainLogo} alt="Top Image" className="w-[95%] h-[95%] rounded-lg" />
          </div>
          
          {/* Bottom Container */}
          <div className="relative overflow-hidden rounded-lg">
            <h1 className="text-2xl mb-2 text-white font-semibold">Your Path to Peaceful Resolutions</h1>
            {/* Text Content */}
            <div className="relative z-10 text-white backdrop-blur p-3  text-center">
              <h2 className="text-l mb-2 font-normal">Experience a transformative way to resolve conflicts with our skilled peacemakers. Through open communication and a focus on mutual interests, we guide parties towards resolutions that meet their unique needs.</h2>
              <a href="#about">
              <button className="text-lg bg-[#0D90C2] px-3 py-2 w-64">Discover More</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
