import React, { useState , useEffect , useRef} from "react";
import gsap from "gsap";
import { HammerBottom, NavLogo } from "../assets";
import Footer from "./Footer";

export default function Bottom() {
  const myComponentRef = useRef(null);
  const myComponentRef2 = useRef(null);

  useEffect(() => {
    // Use GSAP to animate the component
    gsap.from(myComponentRef.current, {
      y: -150, // Move from top (you can adjust this value)
      opacity: 0, // Start with opacity 0 for fade effect
      duration: 3, // Animation duration in seconds
      ease: 'power3.out', // Easing function (optional)
    });
  }, []);
  useEffect(() => {
    // Use GSAP to animate the component
    gsap.from(myComponentRef2.current, {
      y: 150, // Move from top (you can adjust this value)
      opacity: 0, // Start with opacity 0 for fade effect
      duration: 3, // Animation duration in seconds
      ease: 'power3.out', // Easing function (optional)
    });
  }, []);
  return (
    // <div className="bg-gradient-to-b from-black via-black to-gray-800 h-screen">
    <div className="bg-[#101010] h-fit w-screen">
      {/* Your content here */}
      <div className="flex flex-col justify-around py-24 md:flex-row h-full w-full">
        {/* <div className="h-[200px] w-[300px] bg-[rgba(217, 217, 217, 0.05)] text-center my-24"> */}
        <div className="rounded-xl md:border md:border-opacity-25 md:border-gray-300 md:bg-opacity-5 md:bg-gray-300 md:backdrop-filter md:backdrop-blur-2xl md:p-12 h-fit p-2" ref={myComponentRef} >
          <h1 className="md:text-7xl text-4xl md:font-black font-bold text-[#0D90C2] text-center py-4">Contact Us</h1>
          <span className="text-sm md:text-xl font-bold text-white block mx-auto pl-7">For Support : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="text-xs text-gray-200 md:text-xl font-normal">+91 88981 88981</span></span>
          <span className="text-sm md:text-xl font-bold text-white block mx-auto pl-7">For Queries : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="text-xs text-gray-200 md:text-xl font-normal">+91 99778 88779</span></span>
          <span className="text-sm md:text-xl font-bold text-white block mx-auto pl-7">Email support : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className="text-xs text-gray-200 md:text-xl font-normal">customer.support@amicable.com</span></span>
          <h2 className="text-white font-black text-center py-5 md:text-2xl font">For more info visit : <br /> <span className="text-[#0D90C2] font-normal text-lg"> info@amicable.com </span></h2>
        </div>
        <div className="img" ref={myComponentRef2}>
          <img src={HammerBottom} alt="" />
        </div>
      </div>
      
      <Footer/>
    </div>
  );
}
